.consult-form-product{

  .consult{
    @include respond-down(small){
      padding: 40px 0px;
    }
  }

  .consult__form{



    
    padding: 0 82px 0 83px;

    @include respond-down(large){
      padding: 0 50px 0 50px;

    }

    @include respond-down(small){
      padding: 0px 20px;
    }


    .consult__form-group{
      .policy {
        text-align: center;
      }
      width: 100%;
    }
    .consult__input{

      &.name {
        flex: 1 1 auto;

        input {
          width: 100%;
        }
      }

      &.email-phone {
        flex: 1 1 auto;

        input {
          margin: 0px;
          width: 100%;
        }
      }


    }

    .consult__policy.policy{
      bottom: -25px;

      @include respond-down(small){
        position: static;
        margin-top: 20px;
      }
    }

    .consult__button {
      margin-left: 15px;

      @include respond-down(small){
        margin: 0;
      }
    }
    // @media (min-width:784px) {
    //   .consult__input:not(input) {
    //     margin: 0px 15px 0px 0px;
    //     &:last-child {
    //       margin: 0;
    //     }
    //   }
    // }
    // @media (max-width:783px) {
    //   .consult__input:not(input) {
    //     margin: 0px 0px 20px 0px;
    //   }
    // }
  }
}
