.modal-form-wrapper{
  padding: 40px 30px;
  padding-bottom: 0px;
  max-width: 350px;
  @include respond-up(medium){
    max-width: initial;
  }

  &_padding {
    padding: 0px 10px 0px 0px;
    @media (min-width:480px) {
      padding: 40px 30px;
    }
  }

  .form-policy {
    margin-top: 20px;
    margin-bottom: 0px;


    @include respond-down(small){
      min-width: 310px;
      margin-left: 0px;
    }

    &__contain{
      margin-bottom: 0px;
    }
  }

  @include respond-up(medium){
    min-width: 400px;
  }
  @include respond-up(small){
    min-width: 310px;
  }

  h3{
    margin-bottom: 20px;
  }

  label{
    font-size: 16px;
    color: black;

    &.form-footer__argee{
      font-size: 12px;
      line-height: 12px;
    }

    &.required {
      &::after {
        content: '*';
        color: red;
      }
    }
  }

  .form-field{
    margin-top: 20px;

    &.center{
      text-align: center;
    }
  }

  textarea{
    height: 120px;
    padding: 10px 20px;
  }

  .no-br{
    .policy{
      br{
        display: none;
      }
    }
  }
  .policy{
    max-width: 300px;
    label{
      font-size: 12px;
    }
  }

  button{
    &.default{
      max-width: 350px;
      @include respond-down(small){
        max-width: 250px;
      }
    }
  }
  .form-row{
    display: flex;
    margin: 0 -10px 20px -10px;

    &__contain{
      margin-bottom: 0px;
    }

    .form-field{
      margin: 0 10px;
      flex: 1 1 auto;

      @include respond(small){
        margin: 0px;
        margin-bottom: 20px;
      }
    }
    @include respond(small){
      display: block;
    }
  }
}


.success{
  .form-field {
    width: 100%;
    @media (min-width:490px) {
      width: 490px - 60;
    }
  }
  .form-field.agree,
  button.wide {
    display: none;
  }
}
.success-wrapper{
  // position: absolute;
  // top: 0;
  // left: 0;
  // right: 0;
  // bottom: 0;
  height: 140px;
  width: 100%;
  padding-left: 135px;
  background: #ffffff url("../images/Request/success-bg-check.jpg") 0 50% no-repeat;
  background-size: 120px;
  display: flex;
  flex-direction: column;
  z-index: 0;
  justify-content: center;
  transition: $default-transition;
  @media (min-width:480px) {
    width: 420px;
  }

  .success-holder{

    .heading-success{
      font-weight: bold;
      font-size: 18px;
      line-height: 30px;
      margin-bottom: 20px;
    }

    .text{
      display: flex;
      padding: 0;
      font-size: 14px;
    }
    .button_holder{
      max-width: 210px;
      margin-top: 20px;

      .button{
        width: 100%;
      }
    }
  }
}
.product-consult-form,
.product-request-form,
.product-recall-form,
.product-calculate-form,
.product-tech-form,
.product-callback-form {
  &_hide {
    display: none;
  }
}
.product-request-form{
  h1{
    text-align: center;
    margin: 40px 0px 15px 0px;
    @include respond-down(small){
      margin: 30px 0px 15px 0px;
    }
  }

  .sub-title {
    text-align: center;
    margin-bottom: 40px;
    @include respond-down(small){
      margin-bottom: 20px;
    }
    span {
      display: inline-block;
      max-width: 70%;
      @include respond-down(small){
        max-width: 90%;
      }
    }
  }

  textarea{
    resize: none;
  }
  button{
    max-width: 350px;
    @include respond-down(small){
      max-width: 250px;
    }
  }

  .policy{
    br{
      display: none;
    }
  }

  label{
    &.required{
      &:after{
        content: '*';
        color: red;
      }
    }
  }

  .radio-list{
    margin-top: 20px;
    li{
      margin-bottom: 15px;
      input[type=radio]{
        display: none;
        &:checked{
          &+label{
            &:after{
              opacity: 1;
            }
          }
        }
      }
      label{
        position: relative;


        &:after{
          border-radius: 20px;
          opacity: 0;
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%) translateX(50%);
          width: 12px;
          height: 12px;
          background: $orange;
          content: '';
          transition: $default-transition;
        }

        &:before{
          content: '';
          width: 20px;
          height: 20px;
          border: 2px solid $blue;
          display: inline-block;
          vertical-align: middle;
          margin-right: 10px;
          border-radius: 20px;
        }
      }
    }
  }

  .center {
    .agree {
      position: relative;

      .policy {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  &.not-ru{
    @include respond-up(medium){
      min-width: 500px;
    }
  }
}

.recaptcha-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}
