.certs{
  @include respond-property(margin-top, 20px, 30px, 40px, 40px, 60px, 60px);

  .certs-list{
    flex-wrap: wrap;
    .cert-item{
      width: 33.3333%;
      margin-bottom: 20px;

      @include respond(medium){
        width: 50%;
      }

      @include respond(small){
        width: 100%;
      }
    }
  }
}
