.product-page{
  $topInfoHeight: 601px;
  $topInfoHeightLarge: 450px;
  $topInfoHeightMedium: 450px;
  $topInfoHeightSmall: 300px;

  $minHeightOneSlide: 520px;


  @include respond-property(padding-bottom, 30px, 30px, 40px, 50px, 60px, 60px);

  &.no-padding{
    @include respond-property(padding-bottom, 0px, 0px, 0px, 0px, 0px, 0px);
  }

  @import "_slider";
  @import "_aside";
  @import "_read";
  @import "_blocks";
  @import "_consult-form";
  @import "_another";
  @import "_certs";

  h2 {
    font-weight: $bold;
    @include respond-property(margin-bottom, 20px, 20px, 20px, 20px, 25px, 25px);
    display: flex;
    align-items: center;
    @include respond(small) {
      text-align: center;
      display: block;
    }

    .compare-link{
      font-size: 16px;
      padding-left: 30px;
      background: transparent url("../svg/compare.svg") 0 50% no-repeat;
      background-size: 25px 20px;
      @include respond-down(medium){
        display: none;
      }
    }
  }
  // product-page__certificates-image
  &__certificates-image {
    display: none;
  }
}


.modal__container{
  .wrapper-table-view{
    @include respond-down(small){
      max-width: calc(100vw - 10px);
      overflow: auto;
    }
    @import "_tables";

    table{
      border: 1px solid $blue;
      td{
        white-space: nowrap;
      }
    }
  }
}

.modal__bg.table{
  @include respond(small){
    padding: 0px 5px;

    .modal__layout{
      padding: 0px;
    }
  }
}


.modal__container{
  .modal__content{
    .chars-list-wrapper{
      .char-item{
        margin-bottom: 30px;


        .item-name{
          font-size: 16px;
          font-weight: 500;
          margin-bottom: 20px;

        }
        li:before{
          content: '–';
          margin-right: 10px;
          font-size: 16px;
        }
      }
    }
  }
}

.modal__container.table{
  padding: 0px;
  .modal__content{
    padding: 15px;
    background: #ffffff;
  }
  .wrapper-table-view{
    max-width: 100vw;
    overflow: auto;
  }
  .modal__closer{
    top: -40px;
    right: 0;
    width: 50px;
    height: 50px;
    background: #ffffff;
    text-align: center;
    line-height: 50px;
  }
}

.ru {
  .product-page {
    .aside-product {
      .heading-wrapper {
        .heading {
          @include respond(small) {
            padding: 15px 10px 15px 55px;
          }
        }
      }
    }
  }
}
