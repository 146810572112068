.blocks-container {
  @include respond-property(margin-top, 35px, 30px, 40px, 40px, 60px, 60px);
  position: relative;

  &.no-margin {
    @include respond-property(margin-bottom, 0px, 0px, 0px, 0px, 0px, 0px);
  }

  .block-container {
    @include respond-property(margin-bottom, 35px, 30px, 40px, 40px, 60px, 60px);

    &.no-margin {
      @include respond-property(margin-bottom, 0px, 0px, 0px, 0px, 0px, 0px);
    }

    .image-block-left-right-container {
      display: flex;

      @include respond(small) {
        display: block;
      }

      .left-image {
        flex: 1 1 auto;
        max-width: 482px;
        text-align: center;

        img {
          max-width: 350px;
        }

        @include respond(small) {
          text-align: center;
          img {
            max-height: 235px;
          }
        }
      }

      .right-content {
        flex: 1 1 auto;
        margin-left: 30px;
        max-width: 426px;

        @include respond-down(small) {
          margin-left: 0px;
        }

        .block-images-list {
          .item-list {

            @include respond-down(small) {
              text-align: center;
            }

            &:after {
              display: table;
              content: '';
              width: 100%;
              clear: both;

            }

            .point {
              float: left;
              width: 54px;
              @include respond-down(small) {
                display: inline-block;
                vertical-align: top;
                width: 14px;
                margin-top: 5px;
              }

              span {
                width: 14px;
                height: 14px;
                display: block;
                border-radius: 50%;
              }
            }

            .item-description {
              float: left;
              width: calc(100% - 54px);

              @include respond-down(small) {
                float: none;
                display: inline-block;
                vertical-align: top;
                text-align: left;
                margin-left: 10px;
                width: auto;
                max-width: calc(100% - 25px);
              }

              .description {
                margin-bottom: 25px;
                @include respond-down(small) {
                  margin-top: 10px;
                }
              }

              h4 {
                font-size: 16px;
                font-weight: $bold;
                margin-bottom: 18px;

                @include respond-down(small) {
                  margin-bottom: 0px;
                }
              }
            }
          }
        }


        .block-image-right-container {
          p {
            font-size: 16px;
            margin-bottom: 18px;
          }

          ul {
            li {
              margin-bottom: 15px;
              padding-left: 20px;
              position: relative;

              &:before {
                content: '—';
                width: 20px;
                position: absolute;
                left: 0;
              }
            }
          }
        }
      }
    }

    .icons-list {
      @include respond-property(margin-top, 20px, 30px, 40px, 40px, 60px, 60px);
      white-space: nowrap;
      margin: 0 -20px;
      display: flex;
      align-items: center;

      @include respond(middle) {
        white-space: normal;
        flex-wrap: wrap;
      }
      @include respond(small) {
        display: block;
        white-space: normal;
      }

      li {
        width: 33.3%;
        white-space: normal;
        @include respond(middle) {
          width: 50%;
        }
        @include respond(small) {
          width: 100%;
        }

        .icon-item-wrapper {
          padding: 20px;
          display: flex;
          align-items: center;

          .icon {
            width: 60px;
            flex: 0 0 60px;

            @include respond(middle) {
              width: 40px;
              flex: 0 0 40px;
            }
          }

          .name {
            margin-left: 20px;
            font-weight: $bold;

            @include respond(middle) {
              font-size: 13px;
            }
          }
        }
      }
    }

    .content-raw {
      position: relative;
      width: 100%;


      p {
        font-size: 14px;

        @include respond-down(small){
          font-size: 14px;
        }
      }


      ul {
        margin: 20px 0px;
        columns: 2;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        @include respond-down(small) {
          display: block;
          columns: 1;
          padding: 0 20px;
        }

        &:last-child {
          margin-bottom: 0px;
        }

        * {
          font-size: 16px;
        }

        li {
          margin-bottom: 20px;
          padding-left: 23px;
          position: relative;
          break-inside: avoid-column;
          width: 45%;

          @include respond(small) {
            width: 100%;
          }

          &:before {
            content: '–';
            color: $orange;
            font-size: 30px;
            position: absolute;
            left: 0;
            top: 0;
            line-height: 0.6;
            font-weight: $bold;
          }
        }
      }

      ol {
        list-style: decimal;
        margin: 20px 20px;
        max-width: 500px;
        columns: 2;

        &:last-child{
          margin-bottom: 0px;
        }
        @include respond-down(small) {
          padding: 0 20px;
        }

        * {
          font-size: 16px;
          font-weight: $bold !important;
        }

        li {
          margin-bottom: 20px;
          break-inside: avoid-column;
        }
      }

      &:hover {
        .table-full-width {
          opacity: 1;
        }
      }

      &.content-full-width {
        @import "_lists";
      }


      @import "_tables";

      &.tables-full-width {
        a{
          text-decoration: underline;
          cursor: pointer;
          transition: $default-transition;
          &:hover{
            color: #ff9e00;
          }
        }
        @import "_tables_full_width";

        @include respond-up(medium){
          .chars-table-wrapper{
            &+p{
              margin-top: 20px;
            };
          }
        }
      }
    }

  }

  [data-parent-change-orientation] {
    position: relative;

    .table-full-width-wrapper {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 30px;
      z-index: 0;
    }

    &:hover {
      .table-full-width {
        opacity: 1;
      }
    }

    .table-full-width {
      position: sticky;
      float: right;
      top: 100px;
      width: 50px;
      height: 50px;
      background: $orange;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0.3;
      transition: opacity 0.4s;;

      &.psticky-sticky {
        position: fixed;
        left: 50%;
        margin-left: calc((1230px / 4));
        transform: translateX(-100%);
        transition: none;
        @media all and(max-width: #{$max-row-width}px) {
          margin-left: 0px;
          left: calc(75% - 28px);
        }
        @media all and(max-width: 1190px) {
          margin-left: 0px;
          left: calc(75% - 15px);
        }

        @include respond-down(medium) {
          left: auto;
          right: 36px;
          transform: none;
        }
      }

      span {
        transition: $default-transition;
        width: 25px;
        height: 25px;
        background: transparent url("../svg/full-size.svg") 50% 50% no-repeat;
        background-size: 25px;
        display: block;
      }

      @include respond(small) {
        display: none;
      }
    }
  }

  [data-table-bar] {
    .ps__rail-x {
      bottom: 0 !important;
      top: auto !important;
    }
  }

}
