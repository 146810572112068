.aside-product{
  background: $blue;
  position: relative;

  @include respond(small){
    margin-top: 20px;
  }

  .aside-wrapper{
    height: 100%;
    padding-bottom: 30px;
    margin-bottom: 30px;

    @include respond-down(medium){
      height: auto;
    }

  }

  @include respond-down(large){
    height: $topInfoHeightLarge;
  }

  @include respond-down(medium){
    height: auto;
  }

  @include respond-up(large){
    min-height: $minHeightOneSlide;
  }

  &.long{
    @include respond-up(large){
      min-height: auto;
    }
    
    @include respond-down(large){
      height: auto;
    }
    
    @include respond-down(medium){
      height: auto;
    }
  }




  .heading-wrapper{
    @include respond(medium){
      display: flex;
      flex-wrap: nowrap;
    }
    .heading{
      padding: 13px 10px 9px 55px;
      background: transparent url("../svg/gears.svg") 10px 50% no-repeat;
      font-weight: bold;
      background-size: 32px 32px;
      color: #fff;
      border-bottom: 1px solid;

      h3{
        color: #ffffff;
        font-size: 24px;
        font-weight: $bold;
      }


      &.en{
        padding: 15px 10px 15px 55px;
      }

      @include respond(middle){
        font-size: 12px;
      }

      @include respond(medium){
        max-width: 380px;
        display: flex;
        align-items: center;
      }

      @include respond(small){
        padding: 15px 10px 15px 11px;
        font-size: 11px;
      }
    }
  }


  .wrapper{
    padding: 25px 10px 36px 25px;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    max-height: calc(100% - 90px);
    position: relative;

    .all-chars{
      position: absolute;
      bottom: 15px;
      color: $orange;
      display: flex;
      align-items: center;
      left: 50%;
      transform: translateX(-50%);
      white-space: nowrap;
      .dashed{
        border-bottom: 1px dashed;
        display: inline-block;
      }
      .arrow{
        margin-left: 3px;
      }
    }


    .simplebar-track{
      width: 6px;
      right: 6px;
      .simplebar-visible{
        width: 2px;
      }
    }

    .simplebar-mask{
      &:after{
        content: '';
        left: 0;
        right: 0;
        position: absolute;
        bottom: -30px;
        height: 50px;
        width: 100%;
        background: #001e4d;
        box-shadow: 0px -20px 46px 13px #001E4D;
      }
    }

    .simplebar-content{
      @include respond(medium){
        .chars-list-wrapper{
          display: grid;
          grid-template-columns: 50% 50%;
        }

      }
    }

    .chars-list{
      max-height: calc(100% - 90px);
      overflow: hidden;
      position: relative;



      .char-item{
        margin-bottom: 30px;

        @include respond(medium){
          margin-bottom: 0px;
        }

        &:last-child{
          margin-bottom: 0px;
        }

        .item-name{
          font-size: 16px;
          font-weight: $bold;
          margin-bottom: 20px;

          @include respond(middle){
            font-size: 14px;
          }

          @include respond(medium){
            margin-bottom: 10px;
          }
        }

        .spec-list{
          li{
            &:before{
              content: '—';
              margin-right: 10px;
            }
          }
        }
      }
    }

    .files-list{
      max-height: calc(100% - 90px);
      overflow: hidden;
      position: relative;

      .file-item{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 10px;
        &:last-child {
          margin-bottom: 0;
        }

        svg{
          width: 25px;
          margin-right: 20px;
        }
        a{
          color: $white;

          &:hover{
            color: $orange;
          }
        }
      }
    }

    &.no-padding-bottom{
      padding-bottom: 0px;
    }
    &.padding-top {
      padding-top: 10px;
    }
  }

  .button-container-price-container{
    text-align: center;
    padding: 0 36px 0 37px;

    @include respond-down(large){
      padding: 0 25px 0 26px;

    }
    @include respond(small){
      padding-bottom: 30px;
    }

    .price-container{
      font-weight: $bold;
      color: #ffffff;
      font-size: 24px;


      @include respond-up(middle){
        margin-bottom: 15px;
      }

      @include respond(small){
        margin-bottom: 20px;
      }
    }
    .button{
      width: 100%;
      padding: 14px 0px;

      @include respond(small){
        max-width: 300px;
      }
    }

    @include respond(small){
      position: static;
    }
    @include respond(medium){
      position: static;
      display: flex;
      justify-content: space-between;
      flex: 1 1 auto;
      align-items: center;
      padding: 11px 20px;

      .price-container{
        white-space: nowrap;
      }
      .button{
        max-width: 230px;
        margin-left: 20px;
      }
    }
  }
}