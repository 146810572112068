.read-product {
    background-color: #fff;
    // .read-product__wrap
    &__wrap {
        position: relative;
        padding: 27px 17px 33px;
        &:before, &:after {
            content: '';
            position: absolute;
            left: 0;
            height: 15px;
            width: 100%;
        }
        &:before {
            top: -15px;
            background: $blue;
        }
        &:after {
            bottom: -15px;
            background: $orange;
        }
    }
    // .read-product__h3
    &__h3 {
        margin-bottom: 17px;
        font-family: $proximaNova;
        font-weight: 700;
        font-size: 24px;
        line-height: 140%;
        color: $orange;
    }
    // .read-product__ul
    &__ul {
      *:last-child {
            margin-bottom: 0;
        }
    }
    // .read-product__li
    &__li {
        margin-bottom: 20px;
    }
    // .read-product__link
    &__link {
        font-family: $proximaNova;
        font-size: 14px;
        line-height: 140%;
        text-decoration: underline;
        color: #000;
        transition: $default-transition;
        &:hover {
            color: $orange;
            text-decoration: underline;
        }
    }
}
