.banner {
  @media (max-width: 650px) {
    display: none;
  }

  &__link {
    display: block;
    position: relative;
    height: 0;
    padding-bottom: 25.29%;
    background-size: contain;
    background-position-y: center;
    background-repeat: no-repeat;
  }

  &__button {
    position: absolute;
    top: calc(50% - 20px);
    right: 5%;
    padding: 11px 18px 11px 18px;
    font-size: 13px;

    @include respond-down(medium) {
      top: calc(50% - 17px);
      right: 4%;
      padding: 8px 15px 8px 15px;
    }

    @include respond-down(small) {
      top: calc(50% - 16px);
      right: 3%;
      padding: 8px 12px 7px 12px;
      font-size: 12px;
    }

    &.banner-1 {
      top: calc(50% - 20px);
      right: 7%;

      @include respond-down(small) {
        right: 5%;
      }
    }

    &.banner-28 {
      top: calc(50% - 17px);
      right: 7%;
    }

    &.banner-32 {
      top: calc(47.5% - 17px);
    }

    &.banner-35 {
      top: calc(51.5% - 17px);
    }

    &.banner-55 {
      top: calc(49% - 17px);
    }
  }
}
