.catalog-part {
  flex: 1 1 auto;
  margin-left: 32px;

  @include respond-down(medium) {
    margin-left: 0px;
  }


  .catalog-list {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    margin-top: 30px;
  }

  .category-card, .sub-category {
    background: #FFFFFF;
    display: flex;
    flex: 1 1 100%;

    @include respond(small) {
      display: block;
    }

    .left-side {
      flex: 1 1 385px;
      width: 385px;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      border-right: 1px solid #F5B142;
      position: relative;
      padding: 30px;

      @include respond-down(middle) {
        flex: 1 1 350px;
        width: 350px;
      }

      @include respond(small) {
        width: 100%;
        flex: 1 1 auto;
        border: none;
        padding: 0px;
      }

      h2 {
        font-size: 21px;
        text-transform: uppercase;
        @include respond(small) {
          font-size: 14px;
        }
      }

      .product-card__subtitle {
        font-size: 14px;
        color: #4D4D4D;
        margin-bottom: 12px;
      }

      a {
        display: block;
        color: black;
        top: 100px;
        position: sticky;
        padding: 50px 0px;
        text-align: center;

        @include respond(small) {
          padding: 10px 0px;
          position: static;
        }
      }

      .image {
        @include respond-down(small) {
          display: none;
        }
      }
    }

    .right-side {
      flex: 1 1 50%;

      .button {
        padding: 12px 10px 14px 10px;
        font-size: 12px;
        @include respond(middle) {
          font-size: 10px;
        }
      }

      .buttons-holder {
        margin-top: 10px;

        @include respond-down(small) {
          .button {
            width: 100%;
          }
        }
      }

      .together {
        font-size: 0px;
        white-space: nowrap;

        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: stretch;
        justify-content: flex-start;
        align-content: center;

        @include respond(small) {
          white-space: normal;
        }

        .button {
          @include respond-up(medium) {
            max-width: 150px;
          }

          @include respond(small) {
            width: 100%;
          }
        }

        .button:first-child {

          display: flex;
          align-content: space-around;
          white-space: break-spaces;
          align-items: center;
          justify-content: center;
          flex-wrap: nowrap;
          flex-direction: row;

          @include respond-up(medium) {
            border-bottom-right-radius: 0px;
            border-top-right-radius: 0px;
          }
          @include respond(small) {
            border-radius: 20px 0px 0px 20px;
            margin-bottom: 20px;
          }
        }

        .button {
          transition: $default-transition;
        }

        .button:first-child {
          background: transparent;
          border: 1px solid $orange;

          &:hover {
            background: $orange;
            color: #fff;

            & + .button {
              background: transparent;
              color: black;
            }
          }
        }

        .button:last-child {
          color: #FFFFFF;
          border: 1px solid $orange;

          width: auto;
          height: auto;
          white-space: break-spaces;
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: center;
          align-content: center;
          align-items: center;

          @include respond-up(medium) {
            border-bottom-left-radius: 0px;
            border-top-left-radius: 0px;
          }

          @include respond-down(small) {
            margin-bottom: 20px;
            border-radius: 0px 20px 20px 0px;

            &:hover {
              color: black;
            }
          }
        }
      }


      h3 {
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 10px;
        color: black;
      }

      .product-card__subtitle {
        font-size: 14px;
        color: #989898;
      }

      .product-info-holder {
        padding: 30px;

        @include respond-down(middle) {
          padding: 30px 15px;
        }

        @include respond(small) {
          padding: 0px;
        }
      }

      ul {
        display: flex;
        flex-direction: column;
        height: 100%;

        li {
          flex: 1 1 50%;
          border-bottom: 1px solid $orange;
          display: flex;
          align-items: center;
          padding: 30px 10px 30px 0px;

          @include respond(small) {
            padding: 0px;
          }

          &:last-child {
            border-bottom: transparent;
          }

          .sub-category {
            display: flex;
            align-items: center;

            @include respond(small) {
              display: block;
              padding: 15px 10px;
            }

            .left {
              width: 170px;
              flex: 0 0 auto;
              text-align: center;

              @include respond(small) {
                width: 100%;
                text-align: center;
              }
            }

            .right {
              flex: 1 1 auto;

              h3 {
                a {
                  color: black;
                }
              }
            }
          }

        }
      }

    }

    &.category-card {
      margin-bottom: 32px;

      @include respond(small) {
        margin-bottom: 20px;
      }

      .left-side {
        @include respond-down(small) {
          background: #ecedec;
        }
      }
    }

    &.product-tile {
      width: 100%;
      flex: 1 1 100%;

      @include respond(small) {
        width: 100%;
        flex: 1 1 auto;
        max-width: 100%;
      }


      .left-side {
        padding: 0px;
        min-width: auto;

        @include respond-down(small) {
          background: #FFFFFF;
        }

        @include respond(small) {
          border: none;
        }

        a {
          @include respond-down(small) {
            padding: 30px 0px 10px 0px;
          }
        }
      }

      .right-side {
        display: flex;
        align-items: center;
        @include respond(small) {
          border: none;
        }


        .product-info-holder {
          @include respond(small) {
            padding: 10px 15px;
            width: 100%;
          }

          h3 {
            a {
              color: black;
            }
          }
        }
      }
    }
  }


  .category-list-image-behavior {
    @include respond-down(small){
      margin: 20px 0px;
    }
    &.vertical {
      max-width: 90px;

      @include respond-down(small) {
        max-width: 100%;
      }
    }

    &.horizontal {
      max-width: 135px;
      max-height: 100px;
      @include respond-down(small) {
        max-width: 100%;
        max-height: none;
      }
    }
  }

  .category-image-behavior {
    @include respond-down(small){
      margin: 10px 0px;
    }
    &.vertical {
      max-height: 290px;
      max-width: 250px;
    }

    &.horizontal {
      max-height: 290px;
      max-width: 230px;
    }
  }

  .min-height-product-image-card {
    height: 300px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    @include respond-down(small){
      height: auto;
    }

    .category-list-image-behavior {
      @include respond-down(small){
        margin: 10px 0px;
      }
      &.vertical {
        max-height: 260px;
        max-width: 280px;
        @include respond-down(small) {
          max-height: 230px;
          max-width: 100%;
        }
      }

      &.horizontal {
        max-height: 210px;
        max-width: 100%;

        @include respond-down(small) {
          max-width: 100%;
          max-height: 230px;
        }
      }
    }
  }


}
