* {
  font-size: 14px;
}

ul{
  align-items: flex-start;
  padding: 0px;
  margin-bottom: -20px;

  li{
    width: 48%;
    &:before{
      font-size: 25px;
    }
    @include respond(small) {
      width: 100%;
    }
  }
}

