html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}
html {
  scroll-behavior: smooth;
  min-width: 375px;
}
body {
  line-height: 1;
}
ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
* {
  box-sizing: border-box;
  outline: none;
}
img {
  max-width: 100%;
}

.new-btn {
  display: inline-flex;
  align-items: center;
  width: 100%;
  min-height: 55px;
  padding: 20px 24px;
  font-family: $proximaNova;
  font-size: 14px;
  line-height: calc(17 / 14 * 100%);
  letter-spacing: 0.05em;
  color: #000;
  border-radius: 0;
  transition: background 0.3s ease-in;
  text-align: left;
  text-transform: none;
}