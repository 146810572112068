table {
  width: 100%;
  background: #ffffff;
  table-layout: auto;
  *{
    font-size: 16px;
    @include respond-down(large){
      font-size: 14px;
    }
    @include respond-down(middle){
      font-size: 12px;
    }
  }
  sup{
    font-size: 8px;
    position: relative;
    top: -3px;
  }
  tr {
    &:first-child {
      border-bottom: 1px solid #001e4d;
      td {
        padding: 20px 23px 10px 23px;
        font-weight: 500;
        vertical-align: middle;
        @include respond-down(large){
          padding: 20px 15px 10px 15px;
        }
        @include respond-down(middle){
          padding: 15px;
        }
        p {
          font-weight: $bold;
          margin-bottom: 0px;
          span {
            font-weight: $bold !important;
          }
        }
      }
    }

    td[rowspan], td[colspan]{
      vertical-align: middle;
      p {
        font-weight: 400;
        margin-bottom: 0px;
        span {
          font-weight: $bold !important;
        }
      }
    }

    td{
      padding: 10px 23px;
      border-right: 1px solid $blue;
      border-bottom: 1px solid $blue;
      line-height: 1.1;
      width: auto !important;
      vertical-align: middle;
      @include respond-down(middle){
        padding: 15px;
      }
      p{
        line-height: 1.1;
        margin-bottom: 10px;
      }
      &:last-child{
        border-right: 0px;
      }
    }
  }

  tr {
    td{
      white-space: nowrap;
      text-align: center;
    }
  }

}

// фикс для таблицы https://dimetm.com/products/orange-peel-grapple
table {
  tr {
    td {
      &:last-child {
        border-right: 1px solid $blue;
      }
    }
  }
}
