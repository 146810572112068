.catalog-page {

  h2 {
    font-weight: $bold;
    text-align: center;
    margin-bottom: 20px;
  }

  .category-text {
    font-size: 16px;

    p {
      font-size: 16px;
    }


    @include respond-down(small) {
      font-size: 13px;
      p {
        font-size: 13px;
      }
    }
  }

  .categories-list {
    margin: 40px 0px 60px 0px;

    @include respond-down(small) {
      margin: 15px 0px 30px 0px;
    }
  }

  .catalog-wrapper-part {
    display: flex;
    @import "_menu";
    @import "_banner";
    @import "_catalog";
  }

  .certs-wrapper {
    margin: 40px 0px 60px 0px;

    @include respond-down(small) {
      margin: 15px 0px 30px 0px;
    }
  }
}

.min-height-product-image-card{
  @include respond-up(middle){
    height: 300px;
    display: flex;
    align-items: center;
  }
}


#page .catalog-part [data-parent-file].parent-file{
  position: relative;
  padding-bottom: 60px;

  .down-load-brash-holder{
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    background: #E6E6E6;
    font-size: 11px;
    font-weight: bold;
    padding: 0px 22px 0px 13px;
    transition: $default-transition;

    @include respond-down(small){
      left: 0;
      display: flex;
      justify-content: flex-end;
      padding-right: 20px;
    }

    &:hover{
      background: #f3f3f3;
    }

    .svg-icon{
      width: 20px;
    }

    .icon-link{
      margin-left: 10px;
      font-weight: normal;
      white-space: nowrap;
      &:hover{
        span{
          border-bottom-color: transparent;
        }
      }
      span{
        border-bottom: 1px solid $orange;
        transition: $default-transition;
        margin-left: 3px;
      }
    }
  }
}

.ru {
  .catalog-page {
    .catalog-wrapper-part {
      .catalog-part {
        .category-card {
          .right-side {
            .sub-category:not(.product) {
              h3 {
                font-size: 17px;
              }
            }
          }
        }
      }
    }
  }
}