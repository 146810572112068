.menu-aside {
  flex: 0 0 300px;
  position: relative;
  padding-top: 40px;

  @include respond(middle) {
    flex: 1 1 300px;
    min-width: 250px;
  }
  @include respond-down(medium) {
    display: none;
  }

  .wrapper-aside {
    position: sticky;
    top: 100px;
  }

  .heading {
    color: white;
    background: $blue url("../svg/sibscribe-white.svg") 95% 50% no-repeat;
    padding: 18px 11px 18px 24px;
    font-weight: bold;
    font-size: 17px;
  }

  .catalog-menu-wrapper {
    .root-item {
      background: #fff;
      border-bottom: 1px solid rgba(0, 30, 77, 0.55);

      &:last-child {
        border-bottom: transparent;
      }

      .plus-holder {
        width: 50px;
        position: absolute;
        height: 100%;
        right: 0px;
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        align-items: center;
        justify-content: center;
        transition: $default-transition;

        .arrow {
          width: 18px;
          height: 18px;
          border-radius: 100%;
          display: inline-block;
          background: #D8D8D8 url("../svg/arrow-menu-top-blue.svg") 50% 50% no-repeat;
          background-size: 7px 7px;
          transition: $default-transition;
        }

        &.active {
          .plus {
            &:after {
              opacity: 0;
            }
          }
        }

        .plus {
          width: 18px;
          height: 18px;
          background: #D8D8D8;
          border-radius: 100%;
          display: inline-block;

          &:before {
            width: 6px;
            height: 1px;
            background: black;
            content: '';
            position: absolute;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            top: 50%;
          }

          &:after {
            width: 6px;
            height: 1px;
            background: black;
            content: '';
            position: absolute;
            left: 50%;
            transform: translateX(-50%) translateY(-50%) rotate(90deg);
            top: 50%;
            transition: $default-transition;
          }
        }
      }

      .root-catalog-link {
        height: 50px;
        display: flex;
        align-items: center;
        position: relative;
        padding-right: 50px;
        font-size: 13px;

        @include respond(middle) {
          font-size: 13px;
        }


        &:hover {
          border-top-color: #2b3966;

          .plus-holder {
            background: #2b3966;
          }

          .arrow {
            transform: rotate(-90deg);
          }
        }

        .title {
          color: black;
          font-weight: 600;
        }

        .icon {
          width: 55px;
          flex: 0 0 55px;
          display: flex;
          align-items: center;
          justify-content: center;

          .icon-wrapper{
            width: 35px;
            height: 28px;
            display: flex;
            align-items: center;
            &.vertical{
              width: 28px;
              height: 35px;
            }
          }
        }
      }

      .inner-menu {
        display: none;

        .inner-dropper-item {
          border-bottom: 1px solid #FFFFFF;


          &:last-child {
            border-bottom: transparent;
          }

          a {
            background: rgba(0, 30, 77, 0.51);
            color: #fff;
            height: 50px;
            display: flex;
            align-items: center;
            font-size: 15px;
            padding-left: 15px;
            position: relative;
            transition: $default-transition;

            @include respond(large) {
              height: auto;
              padding: 10px 0px;
            }

            @include respond(middle) {
              font-size: 13px;
              height: auto;
              padding: 10px 0px;
            }

            &:before {
              content: '—';
            }

            .title {
              padding-left: 4px;
              @include respond(large) {
                width: 230px;
              }

              @include respond(middle) {
                width: 230px;
              }
            }

            &:hover, &.active {
              background: $blue;
            }
          }

          .inner-dropper-item-name {
            &.active {
              border-bottom: 1px solid white;
            }
          }
        }

        &.active {
          display: block;
        }

        .inner-menu {
          .inner-dropper-item {
            a {
              padding-left: 30px;

              .plus-holder {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}
