$max-row-width: 1340;
$grid-columns: 12;
$grid: (
  small: (
    from: 0,
    to: 783,
    gutter: 40,
  ),
  medium: (
    from: 784,
    to: 999,
    gutter: 60,
    spacer: 36,
  ),
  middle: (
    from: 1000,
    to: 1189,
    gutter: 36,
  ),
  large: (
    from: 1190,
    to: 1479,
    gutter: 87,
    spacer: 36,
  ),
  xl: (
    from: 1480,
    to: 1699,
    gutter: 87,
    spacer: 36,
  ),
  xxl: (
    from: 1700,
    to: 3000,
    gutter: 87,
    spacer: 36,
  ),
);

$mq-zommed: '(max-width: 1480px) and (min-width: 1280px) and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)';

$proximaNova: 'ProximaN', sans-serif;
$proximaCond: 'ProximaCond', sans-serif;
$proximaExtra: 'ProximaExtra', sans-serif;

$body-font-size: 16px;
$font: $proximaNova, sans-serif;
$h-font: $proximaNova, sans-serif;

$t: transparent;

/**
    Colors
 */
$primary-color: #01c767;
$alert-color: #b50000;
$white: #fff;
$black: #000;
$w: $white;
$b: $black;
$grey6f: #6f6f6f;
$grey7c: #7c7c7c;
$grey76: #767676;
$blue: #001e4d;
$blue2: #072656;
$lightblue: #9ca9be;
$orange: #f5b142;
$greyec: #ecedec;

/**
  Font weight
 */

$bold: 500;
$dem: 800;
$semi: 600;
$extra: 900;

$default-transition: all 0.4s;

$headerHeightMiddle: 75px;
$headerHeightMedium: 75px;
$headerHeightSmall: 75px;
$headerHeight: 85px;
