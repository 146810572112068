table{
  table-layout: auto;
  width: 100% !important;

  *{
    font-size: 12px;
    white-space: nowrap;
    @include respond-down(large){
      font-size: 12px;
    }
    @include respond-down(middle){
      font-size: 12px;
    }
    strong{
      font-weight: 500;
    }
    b{
      font-weight: 500;
    }
  }
  tr {
    &:first-child {
      td {
        padding: 10px 7px 10px 7px;
        text-align: center;
      }
    }

    td{
      text-align: center;
      padding: 10px 7px 10px 7px;
      white-space: normal;
      br:first-child{
        display: none;
      }
      br{
        display: none;
      }
      @include respond-down(middle){
        padding: 10px 7px 10px 7px;
      }
      p{
        margin-bottom: 0px;
      }
    }
  }

  &+p{
    margin-top: 20px;
  }
}
