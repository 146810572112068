.sliders-wrapper {
  @include respond-down(medium) {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
  }
  @include respond-down(small) {
    display: block;
  }

  .main-slider {

    @include respond(medium) {
      flex: 1 1 auto;
      max-width: calc(100% - 190px - 10px);
    }


    .image-slide {
      background: #ffffff;
      text-align: center;

      .slide-wrapper {
        display: flex;
        align-items: center;
        padding: 0 40px;
        text-align: center;

        a {
          display: block;
          width: 100%;
        }

        &.video-wrapper, &.full-width {
          padding: 0px;

          &.video-wrapper {
            &:before {
              transition: $default-transition;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translateX(-50%) translateY(-50%);
              content: '';
              width: 98px;
              height: 98px;
              background: transparent url("../svg/tests__play-button.svg") 50% 50% no-repeat;
              background-size: cover;
              pointer-events: none;
            }
          }


          img {
            object-fit: cover;

            @include respond-down(large) {
              object-fit: cover;
            }

            @include respond(medium) {
              object-fit: cover;

            }
            @include respond(small) {
              object-fit: cover;
            }
          }
        }
      }

      .image-link {
        margin: 0 auto;
      }

      img {
        margin: auto;
        display: flex;
        max-height: 100%;
        max-width: 100%;
        object-fit: contain;
        height: $topInfoHeight;

        @include respond-down(large) {
          height: $topInfoHeightLarge;
        }

        @include respond(medium) {
          height: $topInfoHeightMedium;
        }
        @include respond(small) {
          height: $topInfoHeightSmall;
        }
      }

      &.smalled {
        img {
          max-height: $minHeightOneSlide;
        }
      }
    }
  }

  .nav-slider {
    margin: 10px 0px 0px 0px;

    @include respond(medium) {
      flex: 0 0 190px;
      margin: 0px;
    }


    &.full-list {
      .slick-slide {
        max-width: 240px;

        @include respond(medium) {
          max-width: 190px;
        }
      }
    }

    .slick-list {
      margin: 0 -5px;
      @include respond(medium) {
        margin: 0px;
      }
    }

    .slick-slide {
      max-width: 230px;

      @include respond(medium) {
        max-width: 190px;
      }
    }

    .image-slide {
      margin-bottom: 10px;


      .slide-wrapper {
        position: relative;
        background: #ffffff;
        height: 160px;
        display: flex;
        padding: 10px 0px;
        margin: auto;
        width: 220px;

        @include respond-down(medium) {
          width: 100%;
          height: 123px;
        }

        &:after {
          position: absolute;
          transition: $default-transition;
          left: 0px;
          top: 0px;
          bottom: 0px;
          right: 0px;
          border: 3px solid transparent;
          content: '';
        }

        &.video-wrapper, &.full-width {
          padding: 0px;
          position: relative;

          &:hover {
            &:before {
              transform: rotate(-90deg);

              @include respond-down(medium) {
                transform: rotate(-180deg);
              }
            }
          }

          &.video-wrapper {
            &:before {
              transition: $default-transition;
              position: absolute;
              bottom: 20px;
              right: 20px;
              content: '';
              width: 40px;
              height: 40px;
              background: transparent url("../svg/tests__play-button.svg") 50% 50% no-repeat;
              background-size: cover;
            }
          }


          img {
            width: 100%;
            height: 100%;
            display: flex;
          }
        }


        img {
          object-fit: cover;
          margin: auto;
          max-width: 100%;
          max-height: 100%;

          &.vertical {
            height: 100%;
          }

          &.horizontal {
            width: 100%;
          }
        }
      }
    }

    .slick-slide {

      &.slick-current {
        .slide-wrapper {
          &:after {
            border-color: $orange;
          }
        }
      }
    }
  }
}

