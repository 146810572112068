
body, html {
  &.overflow {
    overflow: hidden;
    position: relative;
  }

  -webkit-text-size-adjust: 100%;
}

html.fancybox-lock {
  overflow: visible !important;

}

body {
  font-size: $body-font-size;
  font-family: $font;
  line-height: 1.25;
  background: $greyec;
}



/**
  Anti-aliasing
 */
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  &, &:visited, &:hover, &:active {
    text-decoration: none;
  }
}

a {
  color: $blue;
}

a.hover-underline {
  border: none !important;
  text-decoration: none !important;

  &:hover {
    .underline-element {
      &:before {
        width: 100%;
      }
    }
  }

  .underline-element {
    position: relative;
    border: none;

    &:before {
      content: "";
      display: block;
      position: absolute;
      background: currentColor;
      width: 0;
      height: 1px;
      left: 0;
      bottom: -1px;
      right: 0;
      transition: width ease-out .3s;
    }
  }
}

.underline {
  border-bottom: 1px solid currentColor;
  transition: all ease .2s;

  &:hover {
    border-bottom-color: $t;
  }

}

.upper, .uppercase {
  text-transform: uppercase !important;
}

.page {
  display: flex;
  width: 100%;
  flex-direction: column;
}

//.ru .page {
//  padding-top: 54px;
//}

@mixin text() {
  font-size: 14px;
  color: $blue;
  line-height: calc(22 / 14);
  @include respond-down(large) {
    font-size: 13px;
  }
  @include respond-down(middle) {
    font-size: 14px;
  }
}

@mixin textBold() {
  @include text();
  font-weight: $bold;
}

@mixin textUnderline() {
  @include text();
  border-bottom: 1px solid currentColor;
  transition: all ease-out .2s;
  &:hover {
    border-bottom-color: $t;
  }
}

@mixin headerFooterPadding() {
  padding: 0 61px 0 50px;
  @include respond(xl) {
    padding: 0 25px;
  }
  @include respond(large) {
    padding: 0 20px;
  }
  @include respond-down(middle) {
    padding: 15px 0px 0 16px;
  }
  @include respond-down(small) {
    padding: 0 12px;
  }
}

@mixin checkboxStyle() {
  position: relative;
  border: 0;
  background: 0;
  outline: none;
  box-shadow: none;
  transform: translateY(-2px);
  &:before {
    background: $white;
    content: "";
    opacity: 1;
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 14px;
    height: 14px;
    border-radius: 2px;
    border: 1px solid $lightblue;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 10px 8px;
  }

  &:checked {
    &:before {
      background-repeat: no-repeat;
      background-size: 12px 10px;
      background-position: center center;
      background-image: url('../svg/galka.svg');
    }
  }
}

@mixin checkboxStyleDark() {
  @include checkboxStyle();

  &:before {
    background: $blue;
  }
  &:checked {
    &:before {
      background-image: url('../svg/galka-orange.svg');
    }
  }
}

.c-blue {
  color: $blue !important;
}

.c-orange {
  color: $orange !important;
}

.h-100 {
  height: 100% !important;
}

.ws-nowrap {
  white-space: nowrap !important;
}

.hide {
  display: none !important;
}

.checkbox-styled {
  @include checkboxStyle;

  &.dark {
    @include checkboxStyleDark
  }
}

b, strong {
  font-weight: $bold;
}


.trapezium {
  position: relative;

  &:after {
    position: absolute;
    content: '';
    left: -20px;
    right: -20px;
    bottom: -20px;
    top: -20px;
    background: #ffffff;
    z-index: -1;
    box-shadow: 0 0 0 1px rgba(245, 177, 66, .36);

    @include respond-down(small) {
      left: 0px;
      right: 0px;
      bottom: 0px;
      top: 0px;
    }
  }
}

.skew {
  transform: skew(-5deg);

  .skew-wrapper {
    transform: skew(5deg);

  }
}

.v-align {
  * {
    display: inline-block;
    vertical-align: middle;
  }
}

@mixin orangeCheckmarks() {
  li {
    display: flex;
    align-items: flex-start;

    &::before {
      content: '';
      display: block;
      width: 15px;
      height: 13px;
      margin-top: 5px;
      margin-right: 23px;
      flex: 0 0 13px;
      background-image: url('../svg/orange-checkmark.svg');
      background-size: contain;
      background-repeat: no-repeat;
      //transform: translateX(-15px);
    }

    span{
      sup{
        font-size: 10px;
        top: -5px;
        position: relative;
      }
    }
  }
}

.orange-checkmarks {
  @include orangeCheckmarks;
}


.pagination-block .pagination-list li {
  .pager-button {
    background: $blue;
  }
}


[data-readmore] {
  position: relative;
  transition: height 0.4s;

  &:after {
    content: '';
    position: absolute;
    left: -40px;
    bottom: 0;
    right: 0;
    height: 57px;
    z-index: 10;
    box-shadow: inset #ecedec 0px -16px 20px 20px;
  }

  &.not-shadow {
    &:after {
      display: none;
    }
  }
}

.logos-associations-container {
  background: #ffffff;
  margin: 20px 0px;
  padding: 15px 0px 20px 0px;

  .title {
    @include respond-property(font-size, 20px, 25px, 25px, 29px, 30px, 30px);
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
  }

  .logos-images-list {
    display: flex;
    justify-content: space-between;
    max-width: 900px;
    margin: 0 auto;

    @include respond-down(small) {
      display: block;
      white-space: nowrap;
    }

    img {
      max-width: 200px;
      margin: 0 20px;
      @include respond-down(small) {
        max-width: 30%;
        margin: 0 1.5%;
      }
    }
  }
}


.styled-header:not(.no-styled) {
  padding-bottom: 8px;
  position: relative;

  &.start{
    justify-content: flex-start;
  }

  &.margin-4rem{
    margin-bottom: 4rem;
  }
  &.margin-3rem {
    margin-bottom: 3.12rem;
  }
  &.left-after{
    &:after{
      left: 0;
      transform: translateX(0%);
    }
  }
  &.center-after-mobile{
    @include respond-down(small){
      &:after{
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
  &:after {
    content: '';
    height: 2px;
    max-width: 90%;
    left: 50%;
    transform: translateX(-50%);
    width: 50px;
    background: $orange;
    position: absolute;
    bottom: 0px;

    @include respond-down(small) {
      &:after {
        height: 1px;
        width: 30px;
      }
    }

  }
}

.text-center {
  text-align: center;
}


.block-index-wrapper {
  margin-bottom: 60px;

  @include respond-down(small) {
    margin-bottom: 45px;

    &.full-width{
      position: relative;
      &:after, &:before{
        right: -20px;
        content: '';
        position: absolute;
        width: 20px;
        top: 0;
        bottom: 0;
        background: #ffffff;
      }
      &:before{
        right: auto;
        left: -20px;
      }
    }
  }
}

.button-wrapper-center-mobile{
  @include respond-down(small){
    text-align: center;
    .button{
      margin-top: 0px;
    }
  }
}
.about-page .reviews .button-wrapper-center-mobile .button{
  @include respond(small){
    margin-top: 0px;
  }
}
img{
  transition: $default-transition;
}
img[src=""] {
  opacity: 0;
  visibility: hidden;
}

.tooltip {
  position: relative;
  cursor: pointer;
}

.tooltip:hover .tooltip-content {
  display: block;
  animation: tooltip 0.5s ease forwards;
}

.tooltip-content {
  display: none;
  opacity: 0;
  position: absolute;
  top: calc(100% + 30px);
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  text-align: center;
  max-width: 300px;
  min-width: 150px;
  padding: 15px;
  border: 1px solid #e9e9e9;
  box-shadow: 0 -4px 16px 0 rgba(0,0,0,0.15);
  background-color: #ffffff;
}

.tooltip-content:before {
  content: '';
  position: absolute;
  top: -5px;
  left: 50%;
  width: 12px;
  height: 12px;
  background-color: #fff;
  transform: translateX(-50%) rotate(45deg);
}

@-moz-keyframes tooltip {
  100% {
    opacity: 1;
    top: calc(100% + 15px);
  }
}

@-webkit-keyframes tooltip {
  100% {
    opacity: 1;
    top: calc(100% + 15px);
  }
}

@-o-keyframes tooltip {
  100% {
    opacity: 1;
    top: calc(100% + 15px);
  }
}

@keyframes tooltip {
  100% {
    opacity: 1;
    top: calc(100% + 15px);
  }
}

.text-content {
  ol {
    list-style: decimal inside;
  }
}

.page-title {
  font-weight: bold;
}

.cookie-agreement {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 15px 10px;
  z-index: 9999999999;
  max-width: 333px + 20px;
  @media(min-width:768px) {
    max-width: 593px + 20px;
  }

  &__closer {
    position: absolute;
    top: 0;
    right: 7px;
    font-size: 25px;
    color: #000;
    text-decoration: none;
    cursor: pointer;
    z-index: 1;
    transition: all 0.3s ease-in;
    &:hover {
      transform: scale(1.2);
    }
  }

  &__block {
    position: relative;
    display: flex;
    border-radius: 40px;
    overflow: hidden;
    box-shadow: 15px 15px 20px -5px rgba(0,0,0,0.5);
  }

  &__text {
    padding: 22px 10px 20px 27px;
    font-size: 9px;
    line-height: calc(11 / 9 * 100%);
    letter-spacing: 0.8px;
    background-color: #ffffff;
    a {
      font-weight: 700;
      transition: all 0.3s ease-in;
      &:hover {
        color : $orange;
      }
    }
    @media(min-width:768px) {
      padding: 35px 20px 45px 35px;
      font-size: 14px;
      line-height: calc(17 / 14 * 100%);
    }
  }

  &__button {
    flex: 0 0 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
    background: #161245;
    border-radius: 0;
    font-size: 14px;
    line-height: calc(17 / 14 * 100%);
    font-weight: 700;
    letter-spacing: 0.83px;
    color: #fff;
    text-transform: uppercase;
    transition: all 0.3s ease-in;
    &:hover {
      background : $orange;
      color : #161245;
    }
    @media(min-width:786px) {
      flex: 0 0 105px;
      font-size: 24px;
      line-height: calc(29 / 24 * 100%);
    }
  }
}